/*
 * Licensed to the Apache Software Foundation (ASF) under one or more
 * contributor license agreements.  See the NOTICE file distributed with
 * this work for additional information regarding copyright ownership.
 * The ASF licenses this file to You under the Apache License, Version 2.0
 * (the "License"); you may not use this file except in compliance with
 * the License.  You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
:root {
    --pf-v5-global--FontSize--md: 14px;
}

.karavan .tools-section {
    padding-top: 0px;
    padding-bottom: 0px;
    padding-right: 0;
    border-bottom: 1px solid #eee;
    background-color: white;
}

.karavan .tools-section .dsl-title {
    display: flex;
    flex-direction: row;
}

.karavan .tools-section .dsl-title .title {
    margin-right: 16px;
}

.karavan .tools-section .tools .header {
    display: flex;
    flex-direction: row;
}

.karavan .tools-section .tools .header .labels {
    height: fit-content;
    margin-left: 3px;
}

.karavan .brand {
    height: 36px;
}

.karavan .pf-v5-c-switch__input:focus ~ .pf-v5-c-switch__toggle,
.pf-v5-c-modal-box .pf-v5-c-switch__input:focus ~ .pf-v5-c-switch__toggle {
    outline: transparent;
    outline-offset: 0;
}

.karavan .header-button {
    margin-left: var(--pf-v5-c-page__header-tools--MarginRight);
}

.karavan .page-header {
    display: flex;
    justify-content: space-between;
}

.karavan .page-header .top-toolbar .pf-v5-c-page__header-tools {
    margin-right: 0;
}

.karavan .page-header .top-toolbar {
    width: 100%;
}

.karavan .page-header .pf-v5-c-page__header-brand {
    padding-right: 16px;
}

.filler {
    width: 100%;
}

.modal-footer {
    width: 100%;
}

.modal-footer .deploy-buttons .pf-v5-c-form__actions {
    justify-content: flex-end;
    margin-top: 16px;
}

.modal-footer .deploy-buttons .pf-v5-c-button {
    margin-left: 16px;
}

.action-buttons .pf-v5-c-form__actions {
    justify-content: flex-end;
    margin-top: 16px;
}

.action-buttons .pf-v5-c-button {
    margin-left: 16px;
}

.pf-v5-c-modal-box__footer .hidden {
    display: none;
}

.upload-buttons .pf-v5-c-form__actions {
    margin-top: 20px;
    justify-content: flex-end;
}

/*integration page*/
.integration-page .integration-card {
    cursor: pointer;
    height: 160px;
}

.integration-page .integration-card .pf-v5-c-card__header {
    padding-right: 6px;
}

.integration-page .integration-card .icon {
    height: 24px;
}

.integration-page .integration-card .delete-button {
    font-size: 17px;
    line-height: 1;
    border: 0;
    padding: 0;
    margin: 0;
    background: transparent;
    color: #b1b1b7;
    visibility: hidden;
}

.integration-page .integration-card:hover .delete-button {
    visibility: visible;
}

/*kamelets*/
.kamelets-page .kamelet-card {
    cursor: pointer;
    height: 160px;
}

.kamelets-page .kamelet-card .pf-v5-c-card__header {
    padding-top: var(--pf-v5-global--spacer--sm);
}

.kamelets-page .kamelet-card .pf-v5-c-card__header .custom {
    margin-left: auto;
}

.kamelets-page .kamelet-card .pf-v5-c-card__header .pf-v5-c-card__header-main {
    display: flex;
    flex-direction: row;
}

.kamelets-page .kamelet-card .pf-v5-c-card__title {
    font-size: 15px;
    font-weight: 400;
}

.kamelets-page .kamelet-card .pf-v5-c-card__body {
    overflow: hidden;
    position: relative;
    line-height: 1.6em;
}

.kamelets-page .kamelet-card .icon {
    height: 24px;
    max-width: 24px;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 5px;
    border: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
}

.kamelets-page .kamelet-card .header-labels {
    padding: 5px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.kamelets-page .kamelet-card .header-labels .pf-v5-c-card__header-main {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.kamelets-page .kamelet-card .footer-labels {
    padding: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.kamelets-page .kamelet-card .version,
.kamelets-page .kamelet-card .support-type,
.kamelets-page .kamelet-card .support-level {
    white-space: nowrap;
}

.kamelets-page .kamelet-card .labels {
    opacity: 0.5;
    font-weight: 200;
}

.kamelets-page .kamelet-card:hover .labels {
    opacity: 1;
}

/*kamelet modal*/
.kamelet-modal-card .pf-v5-c-card__header {
    padding-right: 6px;
    display: flex;
    justify-content: space-between;
}

.kamelet-modal-card .pf-v5-c-card__title {
    font-weight: 600;
}

.kamelet-modal-card .description {
    overflow: hidden;
    position: relative;
    max-width: 600px;
}

.kamelet-modal-card .icon {
    height: 36px;
    width: 36px;
    margin-top: auto;
    margin-bottom: auto;
    border: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
}

.karavan .page {
    height: 100vh;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.karavan main {
    overflow: hidden;
}

/*DSL*/
.karavan .dsl-page {
    height: 100%;
}

.karavan .dsl-page .dsl-page-columns {
    display: block;
    height: 100%;
    background: #fafafa;
    padding-bottom: 66px;
}

.karavan .top-icon {
    height: 24px;
}

.karavan .designer-page {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.karavan .designer-page .project-page-section {
    background-color: white;
}

.karavan .page .main-tabs-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.karavan .page .main-tabs-wrapper::before {
    content: "";
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    border: solid;
    border-width: 0 0 var(--pf-v5-global--BorderWidth--sm) 0;
    border-bottom-color: var(--pf-v5-global--BorderColor--100);
}

.karavan .page .main-tabs-wrapper .main-tabs .top-menu-item {
    display: flex;
    flex-direction: row;
}

.karavan .page .main-tabs-wrapper .main-tabs .top-menu-item .count {
    background: var(--pf-v5-global--active-color--100);
    color: white;
    height: fit-content;
    margin-top: auto;
    margin-bottom: auto;
    min-width: 0px;
}

.karavan
.page
.main-tabs-wrapper
.main-tabs
.pf-v5-c-tabs__link
.pf-v5-c-tabs__item-icon {
    height: 24px;
    margin-right: 0;
}

.karavan .page .main-tabs-wrapper .main-tabs .pf-v5-c-tabs__item-text {
    font-weight: bold;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 6px;
}

.karavan .designer-page .page .main-tabs-wrapper .hide-log {
    white-space: nowrap;
    margin-right: 16px;
}

/*Properties*/
.karavan .dsl-page .properties {
    padding: 10px 10px 10px 10px;
    background: transparent;
    width: 100%;
    height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 20px;
    padding-bottom: 30px;
}

.karavan .pf-v5-c-drawer__splitter {
    width: 2px;
    background-color: #fcfcfc;
}

.karavan .pf-v5-c-drawer__splitter-handle {
    display: none;
}

/*Graph*/
.karavan .dsl-page .graph {
    display: block;
    flex-direction: column;
    height: 100%;
    width: 100%;
    position: relative;
    overflow-y: auto;
}

.karavan .dsl-page .flows {
    width: 100%;
    position: absolute;
    padding-bottom: 66px;
}

.karavan .dsl-page .flows .add-flow {
    margin-top: 16px;
    display: flex;
    justify-content: center;
    gap: 6px;
}

/*connections*/
.karavan .dsl-page .graph .connections {
    position: absolute;
    top: 0;
    left: 0;
    background: transparent;
}

.karavan .dsl-page .graph .connections .icon {
    height: 22px;
    width: 22px;
}

.karavan .dsl-page .graph .connections .arrow {
    stroke: var(--pf-v5-global--Color--200);
    fill: var(--pf-v5-global--Color--200);
}

.karavan .dsl-page .graph .connections .path {
    stroke: var(--pf-v5-global--Color--200);
    stroke-width: 1;
    fill: transparent;
}

.karavan .dsl-page .graph .connections .circle-outgoing,
.karavan .dsl-page .graph .connections .circle-incoming {
    stroke: var(--pf-v5-global--Color--200);
    stroke-width: 1;
    fill: white;
}

.karavan .dsl-page .graph .connections .path-incoming {
    stroke-dasharray: 5;
    -webkit-animation: dashdraw 0.5s linear infinite;
    animation: dashdraw 0.5s linear infinite;
    stroke: var(--pf-v5-global--Color--200);
    stroke-width: 1;
    fill: transparent;
}

.karavan .dsl-page .graph .connections .path-incoming-nav {
    stroke: var(--pf-v5-global--Color--200);
    stroke-width: 1;
    fill: transparent;
}

.karavan .dsl-page .graph .connections .path-direct {
    stroke-dasharray: 0;
    stroke: var(--pf-v5-global--Color--200);
    stroke-width: 0.7;
    stroke-opacity: 0.7;
    fill: transparent;
}

.karavan .dsl-page .graph .connections .path-direct-selected {
    stroke-dasharray: 0;
    stroke: var(--pf-v5-global--active-color--100);
    stroke-width: 1;
    stroke-opacity: 1;
    fill: transparent;
}

.karavan .dsl-page .graph .connections .path-seda {
    stroke-dasharray: 2;
    stroke: var(--pf-v5-global--Color--200);
    -webkit-animation: dashdraw 0.5s linear infinite;
    animation: dashdraw 0.5s linear infinite;
    stroke-width: 1;
    fill: transparent;
}

@keyframes dashdraw {
    0% {
        stroke-dashoffset: 10;
    }
}

.karavan .dsl-page .flows .step-element {
    align-items: center;
    text-align: center;
    display: flex;
    flex-direction: column;
    width: fit-content;
    border-color: var(--pf-v5-global--Color--200);
    border-radius: 42px;
    border-width: 1px;
    min-width: 120px;
    padding: 3px 4px 4px 4px;
    margin: 3px auto 0 auto;
    position: relative;
}

.karavan {
    --step-border-color: var(--pf-v5-global--Color--200);
    --step-border-color-selected: var(--pf-v5-global--active-color--100);
}

.karavan .dsl-page .flows .children {
    display: flex;
    flex-wrap: wrap;
    flex-wrap: nowrap;
    gap: 6px;
}

.karavan .dsl-page .flows .has-child {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 6px;
}

.karavan .dsl-page .flows .hidden-step {
    display: none;
}

.element-builder .add-button {
    top: 0;
    right: 5px;
    font-size: 15px;
    line-height: 1;
    border: 0;
    padding: 0;
    margin: 3px auto 0 auto;
    background: transparent;
    color: var(--pf-v5-global--primary-color--100);
    visibility: hidden;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 16px;
}

.element-builder .add-button svg {
    margin: 0 auto 3px auto;
}

.element-builder:hover .add-button,
.karavan .step-element:hover .add-element-button,
.karavan .step-element:hover .add-button {
    visibility: visible;
}

.dsl-gallery {
    margin-top: 16px;
}

.move-modal {
    width: 270px !important;
}

.move-modal .pf-v5-c-modal-box__body {
    margin-right: 0 !important;
}

.move-modal .pf-v5-m-plain {
    display: none;
}

.yaml-code {
    overflow: auto;
    height: 100%;
    width: 100%;
    padding-bottom: 100px;
}

.pf-v5-c-popover__footer {
    overflow-wrap: anywhere;
}

/*Beans*/
.karavan .rest-page .properties .bean-properties .pf-v5-c-form__group-control {
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.karavan .rest-page .properties .bean-property {
    display: flex;
    flex-direction: row;
    gap: 3px;
}

.karavan .rest-page .properties .bean-property .delete-button {
    padding: 3px;
    color: #b1b1b7;
}

/*YAML*/
.karavan .yaml-page {
    height: 100px;
}


/* Help */
.dont-show {
    margin-right: auto;
}

/* Project Tools */
.karavan .project-builder {
    height: 100%;
}

.karavan .project-builder .tools-section {
    padding-left: 10px;
    padding-right: 10px;
}

.karavan .project-builder .pf-v5-c-toolbar__content {
    padding: 0;
}

.karavan .project-builder .card-header svg {
    margin-right: 6px;
}

.karavan .project-builder .card-disabled {
    opacity: 0.4;
}

.karavan .project-builder .pf-v5-c-form {
    --pf-v5-c-form--GridGap: 1em;
}

.karavan .project-builder .pf-v5-c-card__body {
    --pf-v5-c-card--child--PaddingRight: 0.5em;
    --pf-v5-c-card--child--PaddingBottom: 1em;
    --pf-v5-c-card--child--PaddingLeft: 1em;
}

.karavan .project-builder .pf-v5-c-card__header {
    --pf-v5-c-card--first-child--PaddingTop: 0.5em;
}

.karavan .project-builder .center {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 10px;
    padding: 10px;
}

.karavan .project-builder .center-column {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.karavan .project-builder .footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
}

.karavan .project-builder .footer .progress {
    flex-grow: 4;
}

.karavan .project-builder .footer .buttons {
    height: 60px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.karavan .project-builder .project-properties td {
    padding: 0;
    margin: 0;
}

.karavan .project-builder .project-properties td,
.karavan .project-builder .project-properties tr {
    border: none;
}

.karavan .project-builder .project-properties .delete-button {
    padding: 0 0 0 6px;
    margin: 0;
}

.karavan .project-builder .add-button {
    width: fit-content;
    margin-top: 6px;
}

.root .tooltip-required-field .pf-v5-c-tooltip__content {
    text-align: start;
}

.karavan .kamelet-section {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.karavan .kamelets-page {
    overflow: auto;
    flex-shrink: unset;
    flex-grow: 1;
    background-color: var(--pf-v5-global--BackgroundColor--light-300);
    margin-bottom: 100px;
}

.karavan .scrolled-page {
    overflow: auto;
    flex-shrink: unset;
    flex-grow: 1;
    background-color: var(--pf-v5-global--BackgroundColor--light-300);
}

.karavan .knowledbase-eip-section .pf-v5-c-toggle-group {
    margin: 16px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}