/*
 * Licensed to the Apache Software Foundation (ASF) under one or more
 * contributor license agreements.  See the NOTICE file distributed with
 * this work for additional information regarding copyright ownership.
 * The ASF licenses this file to You under the Apache License, Version 2.0
 * (the "License"); you may not use this file except in compliance with
 * the License.  You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
.karavan .project-log {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    z-index: 200;
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.karavan .project-log .buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    border-top: 1px solid var(--pf-v5-global--BorderColor--100);
    padding-right: 6px;
}

.karavan .project-log .buttons button,
.karavan .project-log .buttons .pf-v5-c-check {
    padding: 8px;
}

.karavan .project-log .buttons .pf-v5-c-check .pf-v5-c-check__label{
    font-size: 12px;
    line-height: 20px;
    padding: 0;
}

.karavan .project-log .pf-v5-c-log-viewer__scroll-container {
    /*height: 100% !important;*/
}

.karavan .project-log .pf-v5-c-log-viewer__text {
    font-size: 12px;
}

.karavan .project-log .log-name {
    --pf-v5-c-label__content--before--BorderWidth: 0;
    --pf-v5-c-label--BackgroundColor: transparent;
    /*margin-right: auto;*/
    font-size: 12px;
}

.karavan .project-log .pf-v5-c-toggle-group .pf-v5-c-toggle-group__item {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.karavan .project-log .pf-v5-c-toggle-group .pf-v5-c-toggle-group__button {
    height: 20px;
    text-align: center;
}